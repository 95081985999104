// --- Icons ---

.icon-link {
  font-size: 18px;
}

button {
  .fas {
    margin-right: 5px;
  }
}
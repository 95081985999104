// Default values
$divider-color: #ccc;
$link-color: #365bbb;
$link-color-active: #000fbb;
$base-background-color: #eee;

$color-light: #bbb;

// Buttons
$button-border-color: #ccc;
$button-background-color: #eee;
$button-background-color-hover: #314e9c;

// Form Elements
$form-border-color: #ccc;
$form-border-radius: 4px;

// SEVERITY_NEUTRAL = 0;
// SEVERITY_OK      = 1;
// SEVERITY_NOTICE  = 2;
// SEVERITY_WARNING = 3;
// SEVERITY_ERROR   = 4;
$color-neutral: #333;
$color-ok: #1db755;
$color-notice: #666;
$color-warning: #ffb300;
$color-error: #d41c1c;

// STATUS 0   = available
// STATUS 1   = not available
// STATUS 2   = available in store 33

$api-status-0: #000;
$api-status-1: #cc0099;
$api-status-2: #34b35a;
$api-status-3: #dd0000;
$api-status-4: #bbb;

// --- FORMS ---
button,
input[type=button],
input[type=submit],
input[type=reset],
a.button {
  display: inline-block;
  padding: 6px 15px;
  margin: 0 5px 0 0;

  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;

  color: #333;
  background: $button-background-color;
  border: 1px solid $button-border-color;
  border-radius: $form-border-radius;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    color: #fff;
    background: $button-background-color-hover;
  }

  vertical-align: bottom;
}

.buttonDisabled {
  &:hover {
    background-color: #eee !important;
    cursor: not-allowed;
    color: #333;
  }
}

a.button {
  padding: 5px 14px;
}

button.button-isolated,
a.button-isolated {
  padding: 0;

  font-size: 24px;
  color: $link-color;

  border: none;
  background: transparent;

  &:hover {
    color: inherit;
    background: inherit;
  }
}

input {
  padding: 5px 10px;
  border: 1px solid $form-border-color;
  border-radius: $form-border-radius;
}

textarea {
  padding: 5px 10px;
  max-width: 100%;
  min-width: 200px;
  min-height: 80px;
  border: 1px solid $form-border-color;
  border-radius: $form-border-radius;
}

input::-webkit-inner-spin-button,
input::-webkit-clear-button {
  display: none;
}

input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 3px;
  right: 0;
  color: black;
  opacity: 1;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 5px;
  -moz-padding-end: 5px;
  -webkit-padding-start: 5px;
  -moz-padding-start: 5px;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: url(/dist/icons/angle-down-solid.svg) right 8px center no-repeat #fff;
  background-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.search-form-input {
  &-small {
    width: 120px;
  }

  &-xsmall {
    width: 60px;
  }
}

.login-form {
  width: 300px;
  margin: 0 auto;
  padding: 10px 40px;
  background: #f8f8f8;
}

.field-wrap {
  margin-bottom: 10px;

  label {
    display: inline-block;
    width: 100px;
  }

  &-submit {
    padding-left: 104px;
    margin-bottom: 40px;

    input {
      background: #fff;
    }
  }
}

.list-purchase {
  // Displaying gray grid
  td {
    border: 1px solid $button-background-color;
  }

  // Fixed width of table fields for screen size 1920*1080
  &-edit-data {
    width: 30px;
  }

  &-note-data {
    width: 150px;
  }

  &-items-data {
    width: 670px;
  }

  &-details-data {
    width: 130px;
  }

  &-buyer-data {
    width: 250px;
  }

  &-crdate-data {
    width: 150px;
  }

  &-num-data {
    width: 75px;
  }

  // Input -> full width of parent element
  &-buyer {
    input {
      width: 240px;
    }
  }

  &-items {
    input {
      width: 635px;
    }
  }

  &-cb-data {
    width: 20px;
  }
}

// Width of input elements
.table-form-header {
  &-note {
    input {
      width: 96% !important;
    }

    .new-item-input {
      width: 100%;
    }
  }

  &-quantity {
    .new-item-input {
      max-width: 50px;
    }
  }

  &-choose-article-size {
    .new-input-color-name {
      display: inline-block;
      font-weight: bold;
    }

    .new-item-input-size {
      float: right;
      max-width: 40px;
    }
  }

  &-discount-price {
    input {
      width: 90% !important;
    }
  }

  &-full-price {
    input {
      width: 90% !important;
    }
  }
}

.single-purchase-product-row, .table-form-header-purchase-products {
  td {
    border: 1px solid $button-background-color;
  }
}

// Spacing between input elements
.single-purchase-product-address-row {
  .select2-container--default {
    margin-left: 25px;
    margin-right: 15px;
  }

  .action-save-bex {
    margin-left: 10px;
  }
}

// Fixed width of table fields for screen size 1920*1080
.single-purchase-product {
  &-checkbox {
    width: 20px;
  }

  &-amount {
    width: 70px;
  }

  &-price-tax {
    width: 150px;
  }

  &-price-discount {
    width: 170px;
  }

  &-price-status {
    width: 170px;
  }

  &-price-notes {
    width: 270px;

    textarea {
      width: 90%;
      max-width: 90%;
      min-height: 114px !important;
    }
  }

  &-price-button {
    width: 90px;
  }

  &-image {
    width: 70px;
  }

  &-name {
    width: 160px;
  }
}

// Input width
.table-form-header-choose-article-size {
  #auto-complete-item {
    width: 40%;
  }

  // Refactoring old css, to get properly width of container
  #new-color-size {
    max-width: none !important;
    width: 40%;
  }
}

// Width of input fields
.single-purchase-product-action-row {
  .single-purchase-product-erp-data {
    width: 500px;

    input {
      width: 35%;
    }
  }

  .single-purchase-product-erp-object-data {
    width: 330px;

    input {
      width: 70%;
      max-width: none !important;
    }
  }

  .single-purchase-product-erp-items-data {
    width: 450px;

    input {
      width: 70%;
    }
  }
}

#erp-receipt--mark-all {
  cursor: pointer;
  color: $color-neutral;
}

//.list-head-row-100 {
//  strong,
//  span,
//  small,
//  nobr,
//  img {
//    opacity: 0.2;
//    nobr {
//      opacity: 1;
//    }
//    span {
//      opacity: 1;
//    }
//  }
//}
/* line 1, scss/_main.scss */

body {
  padding: 20px;
}

/* line 5, scss/_main.scss */

body,
table,
td,
select,
input,
textarea {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333;
}

/* line 20, scss/_main.scss */

h1 {
  margin: 40px 0;
  font-size: 36px;
  font-weight: 400;
}

/* line 26, scss/_main.scss */

h2 {
  font-size: 28px;
  font-weight: 400;
}

/* line 31, scss/_main.scss */

h3 {
  font-size: 22px;
  font-weight: 400;
}

/* line 36, scss/_main.scss */

a {
  color: #365bbb;
}

/* line 39, scss/_main.scss */

a:hover {
  color: #000fbb;
}

/* line 45, scss/_main.scss */

.detail-header {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
}

/* line 2, scss/_flash.scss */

.flash-message-container {
  margin: 20px 0;
}

/* line 6, scss/_flash.scss */

.flash-message-item {
  margin: 0 0 10px 0;
  padding: 15px;
  list-style: none;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}

/* line 19, scss/_flash.scss */

.flash-message-item-0 {
  background-color: #333;
}

/* line 23, scss/_flash.scss */

.flash-message-item-1 {
  background-color: #1db755;
}

/* line 27, scss/_flash.scss */

.flash-message-item-2 {
  background-color: #666;
}

/* line 31, scss/_flash.scss */

.flash-message-item-3 {
  background-color: #ffb300;
}

/* line 35, scss/_flash.scss */

.flash-message-item-4 {
  background-color: #d41c1c;
}

/* line 2, scss/_form.scss */

button,
input[type=button],
input[type=submit],
input[type=reset],
a.button {
  display: inline-block;
  padding: 6px 15px;
  margin: 0 5px 0 0;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  color: #333;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s;
  vertical-align: bottom;
}

/* line 23, scss/_form.scss */

button:hover,
input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover,
a.button:hover {
  color: #fff;
  background: #314e9c;
}

/* line 32, scss/_form.scss */

.buttonDisabled:hover {
  background-color: #eee !important;
  cursor: not-allowed;
  color: #333;
}

/* line 39, scss/_form.scss */

a.button {
  padding: 5px 14px;
}

/* line 43, scss/_form.scss */

button.button-isolated,
a.button-isolated {
  padding: 0;
  font-size: 24px;
  color: #365bbb;
  border: none;
  background: transparent;
}

/* line 53, scss/_form.scss */

button.button-isolated:hover,
a.button-isolated:hover {
  color: inherit;
  background: inherit;
}

/* line 59, scss/_form.scss */

input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* line 65, scss/_form.scss */

textarea {
  padding: 5px 10px;
  max-width: 100%;
  min-width: 200px;
  min-height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* line 74, scss/_form.scss */

input::-webkit-inner-spin-button,
input::-webkit-clear-button {
  display: none;
}

/* line 79, scss/_form.scss */

input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 3px;
  right: 0;
  color: black;
  opacity: 1;
}

/* line 87, scss/_form.scss */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 5px;
  -moz-padding-end: 5px;
  -webkit-padding-start: 5px;
  -moz-padding-start: 5px;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: url(/dist/icons/angle-down-solid.svg) right 8px center no-repeat #fff;
  background-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* line 109, scss/_form.scss */

.search-form-input-small {
  width: 120px;
}

/* line 113, scss/_form.scss */

.search-form-input-xsmall {
  width: 60px;
}

/* line 118, scss/_form.scss */

.login-form {
  width: 300px;
  margin: 0 auto;
  padding: 10px 40px;
  background: #f8f8f8;
}

/* line 125, scss/_form.scss */

.field-wrap {
  margin-bottom: 10px;
}

/* line 128, scss/_form.scss */

.field-wrap label {
  display: inline-block;
  width: 100px;
}

/* line 133, scss/_form.scss */

.field-wrap-submit {
  padding-left: 104px;
  margin-bottom: 40px;
}

/* line 137, scss/_form.scss */

.field-wrap-submit input {
  background: #fff;
}

/* line 145, scss/_form.scss */

.list-purchase td {
  border: 1px solid #eee;
}

/* line 150, scss/_form.scss */

.list-purchase-edit-data {
  width: 30px;
}

/* line 154, scss/_form.scss */

.list-purchase-note-data {
  width: 150px;
}

/* line 158, scss/_form.scss */

.list-purchase-items-data {
  width: 670px;
}

/* line 162, scss/_form.scss */

.list-purchase-details-data {
  width: 130px;
}

/* line 166, scss/_form.scss */

.list-purchase-buyer-data {
  width: 250px;
}

/* line 170, scss/_form.scss */

.list-purchase-crdate-data {
  width: 150px;
}

/* line 174, scss/_form.scss */

.list-purchase-num-data {
  width: 75px;
}

/* line 180, scss/_form.scss */

.list-purchase-buyer input {
  width: 240px;
}

/* line 186, scss/_form.scss */

.list-purchase-items input {
  width: 635px;
}

/* line 191, scss/_form.scss */

.list-purchase-cb-data {
  width: 20px;
}

/* line 199, scss/_form.scss */

.table-form-header-note input {
  width: 96% !important;
}

/* line 203, scss/_form.scss */

.table-form-header-note .new-item-input {
  width: 100%;
}

/* line 209, scss/_form.scss */

.table-form-header-quantity .new-item-input {
  max-width: 50px;
}

/* line 215, scss/_form.scss */

.table-form-header-choose-article-size .new-input-color-name {
  display: inline-block;
  font-weight: bold;
}

/* line 220, scss/_form.scss */

.table-form-header-choose-article-size .new-item-input-size {
  float: right;
  max-width: 40px;
}

/* line 227, scss/_form.scss */

.table-form-header-discount-price input {
  width: 90% !important;
}

/* line 233, scss/_form.scss */

.table-form-header-full-price input {
  width: 90% !important;
}

/* line 240, scss/_form.scss */

.single-purchase-product-row td,
.table-form-header-purchase-products td {
  border: 1px solid #eee;
}

/* line 247, scss/_form.scss */

.single-purchase-product-address-row .select2-container--default {
  margin-left: 25px;
  margin-right: 15px;
}

/* line 252, scss/_form.scss */

.single-purchase-product-address-row .action-save-bex {
  margin-left: 10px;
}

/* line 259, scss/_form.scss */

.single-purchase-product-checkbox {
  width: 20px;
}

/* line 263, scss/_form.scss */

.single-purchase-product-amount {
  width: 70px;
}

/* line 267, scss/_form.scss */

.single-purchase-product-price-tax {
  width: 150px;
}

/* line 271, scss/_form.scss */

.single-purchase-product-price-discount {
  width: 170px;
}

/* line 275, scss/_form.scss */

.single-purchase-product-price-status {
  width: 170px;
}

/* line 279, scss/_form.scss */

.single-purchase-product-price-notes {
  width: 270px;
}

/* line 282, scss/_form.scss */

.single-purchase-product-price-notes textarea {
  width: 90%;
  max-width: 90%;
  min-height: 114px !important;
}

/* line 289, scss/_form.scss */

.single-purchase-product-price-button {
  width: 90px;
}

/* line 293, scss/_form.scss */

.single-purchase-product-image {
  width: 70px;
}

/* line 297, scss/_form.scss */

.single-purchase-product-name {
  width: 160px;
}

/* line 304, scss/_form.scss */

.table-form-header-choose-article-size #auto-complete-item {
  width: 40%;
}

/* line 309, scss/_form.scss */

.table-form-header-choose-article-size #new-color-size {
  max-width: none !important;
  width: 40%;
}

/* line 317, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-data {
  width: 500px;
}

/* line 320, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-data input {
  width: 35%;
}

/* line 325, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-object-data {
  width: 330px;
}

/* line 328, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-object-data input {
  width: 70%;
  max-width: none !important;
}

/* line 334, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-items-data {
  width: 450px;
}

/* line 337, scss/_form.scss */

.single-purchase-product-action-row .single-purchase-product-erp-items-data input {
  width: 70%;
}

/* line 343, scss/_form.scss */

#erp-receipt--mark-all {
  cursor: pointer;
  color: #333;
}

/* line 2, scss/_table.scss */

table {
  width: 100%;
  border-spacing: 0;
  margin: 20px 0;
}

/* line 8, scss/_table.scss */

th,
td {
  text-align: left;
  vertical-align: top;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
}

/* line 16, scss/_table.scss */

th {
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  background: #eee;
}

/* line 24, scss/_table.scss */

.incell-title {
  display: inline-block;
}

/* line 26, scss/_table.scss */

.incell-title-prices {
  width: 60px;
  color: #999;
}

/* line 30, scss/_table.scss */

.incell-title-gateway {
  width: 200px;
  color: #999;
}

/* line 36, scss/_table.scss */

.incell-textline {
  display: inline-block;
}

/* line 38, scss/_table.scss */

.incell-textline-name {
  display: inline-block;
  padding-bottom: 10px;
}

/* line 42, scss/_table.scss */

.incell-textline-prices {
  width: 80px;
  text-align: right;
}

/* line 46, scss/_table.scss */

.incell-textline-gateway {
  width: 200px;
  text-align: left;
}

/* line 50, scss/_table.scss */

.incell-textline-meta {
  color: #999;
  white-space: nowrap;
}

/* line 58, scss/_table.scss */

.product-img-container {
  display: inline-block;
  position: relative;
  margin: 0 5px 3px 0;
}

/* line 62, scss/_table.scss */

.product-img-container img {
  border: 3px solid #333;
}

/* line 65, scss/_table.scss */

.product-img-container .color-size-purchase {
  position: absolute;
  bottom: 5px;
  right: 0;
  padding: 2px 6px;
  background: #333;
  font-size: 11px;
  color: #fff;
}

/* line 77, scss/_table.scss */

.product-img-container .description-purchase {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 6px;
  background: #333;
  font-size: 11px;
  color: #fff;
}

/* line 87, scss/_table.scss */

.product-img-container .description-purchase-note {
  display: none;
  position: absolute;
  top: 0;
  left: 34px;
  width: 200px;
  height: 100px;
  z-index: 1000;
  padding: 4px;
  background: #eee;
  color: #333;
  border: 2px solid #333;
}

/* line 103, scss/_table.scss */

.product-img-container.product-status-8 img,
.product-img-container.product-status-9 img,
.product-img-container.product-status-10 img,
.product-img-container.product-status-11 img {
  border-color: #cc0099;
}

/* line 106, scss/_table.scss */

.product-img-container.product-status-8 .color-size-purchase,
.product-img-container.product-status-8 .description-purchase,
.product-img-container.product-status-9 .color-size-purchase,
.product-img-container.product-status-9 .description-purchase,
.product-img-container.product-status-10 .color-size-purchase,
.product-img-container.product-status-10 .description-purchase,
.product-img-container.product-status-11 .color-size-purchase,
.product-img-container.product-status-11 .description-purchase {
  background: #cc0099;
}

/* line 110, scss/_table.scss */

.product-img-container.product-status-8 .description-purchase-note,
.product-img-container.product-status-9 .description-purchase-note,
.product-img-container.product-status-10 .description-purchase-note,
.product-img-container.product-status-11 .description-purchase-note {
  border: 2px solid #cc0099;
}

/* line 115, scss/_table.scss */

.product-img-container.product-status-19 img,
.product-img-container.product-status-20 img,
.product-img-container.product-status-21 img {
  border-color: #34b35a;
}

/* line 118, scss/_table.scss */

.product-img-container.product-status-19 .color-size-purchase,
.product-img-container.product-status-19 .description-purchase,
.product-img-container.product-status-20 .color-size-purchase,
.product-img-container.product-status-20 .description-purchase,
.product-img-container.product-status-21 .color-size-purchase,
.product-img-container.product-status-21 .description-purchase {
  background: #34b35a;
}

/* line 122, scss/_table.scss */

.product-img-container.product-status-19 .description-purchase-note,
.product-img-container.product-status-20 .description-purchase-note,
.product-img-container.product-status-21 .description-purchase-note {
  border: 2px solid #34b35a;
}

/* line 127, scss/_table.scss */

.product-img-container.product-status-22 img {
  border-color: #dd0000;
}

/* line 130, scss/_table.scss */

.product-img-container.product-status-22 .color-size-purchase,
.product-img-container.product-status-22 .description-purchase {
  background: #dd0000;
}

/* line 134, scss/_table.scss */

.product-img-container.product-status-22 .description-purchase-note {
  border: 2px solid #dd0000;
}

/* line 139, scss/_table.scss */

.product-img-container.product-status-100 img {
  border-color: #bbb;
}

/* line 142, scss/_table.scss */

.product-img-container.product-status-100 .color-size-purchase,
.product-img-container.product-status-100 .description-purchase {
  background: #bbb;
}

/* line 146, scss/_table.scss */

.product-img-container.product-status-100 .description-purchase-note {
  border: 2px solid #bbb;
}

/* line 153, scss/_table.scss */

.description-purchase:hover .description-purchase-note {
  display: block;
}

/* line 157, scss/_table.scss */

.light-grey {
  color: #bbb;
}

/* line 3, scss/_pager.scss */

.list-purchase-pagination {
  margin-top: 30px;
  padding: 10px 0;
  border-top: 3px solid #ccc;
}

/* line 9, scss/_pager.scss */

.pager {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

/* line 13, scss/_pager.scss */

.pager li {
  display: inline;
  margin: 0 5px 0 0;
}

/* line 18, scss/_pager.scss */

.pager a {
  display: inline-block;
  padding: 10px;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 100%;
  background: #eee;
}

/* line 29, scss/_pager.scss */

.pager a:hover {
  color: #fff;
  background: #314e9c;
}

/* line 3, scss/_icon.scss */

.icon-link {
  font-size: 18px;
}

/* line 8, scss/_icon.scss */

button .fas {
  margin-right: 5px;
}

/* line 2, scss/_navigation.scss */

.main-navigation-list {
  margin: 0;
  padding: 0;
  background: #555;
  border-radius: 4px;
}

/* line 8, scss/_navigation.scss */

.main-navigation-item {
  display: inline-block;
  list-style: none;
}

/* line 12, scss/_navigation.scss */

.main-navigation-link {
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
}

/* line 22, scss/_navigation.scss */

.main-navigation-link:hover {
  color: #fff;
  background: #000;
}
body {
  padding: 20px;
}

body,
table,
td,
select,
input,
textarea {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333;
}

// --- TAGS ---

h1 {
  margin: 40px 0;
  font-size: 36px;
  font-weight: 400;
}

h2 {
  font-size: 28px;
  font-weight: 400;
}

h3 {
  font-size: 22px;
  font-weight: 400;
}

a {
  color: $link-color;

  &:hover {
    color: $link-color-active;
  }
}

// --- Detail Page header ---
.detail-header {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
}
.flash-message {
  &-container {
    margin: 20px 0;
  }

  &-item {
    margin: 0 0 10px 0;
    padding: 15px;
    list-style: none;
    color: #fff;
    font-size: 16px;
    border-radius: $form-border-radius;

    // SEVERITY_NEUTRAL = 0;
    // SEVERITY_OK      = 1;
    // SEVERITY_NOTICE  = 2;
    // SEVERITY_WARNING = 3;
    // SEVERITY_ERROR   = 4;
    &-0 {
      background-color: $color-neutral;
    }

    &-1 {
      background-color: $color-ok;
    }

    &-2 {
      background-color: $color-notice;
    }

    &-3 {
      background-color: $color-warning;
    }

    &-4 {
      background-color: $color-error;
    }
  }
}
// --- List View paginator ---

.list-purchase-pagination {
  margin-top: 30px;
  padding: 10px 0;
  border-top: 3px solid $divider-color;
}

.pager {
  margin-left: 0 !important;
  padding-left: 0 !important;

  li {
    display: inline;
    margin: 0 5px 0 0;
  }

  a {
    display: inline-block;
    padding: 10px;
    width: 20px;

    text-align: center;
    text-decoration: none;

    border-radius: 100%;
    background: $button-background-color;

    &:hover {
      color: #fff;
      background: $button-background-color-hover;
    }
  }
}
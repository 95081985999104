// --- TABLES ---
table {
  width: 100%;
  border-spacing: 0;
  margin: 20px 0;
}

th,
td {
  text-align: left;
  vertical-align: top;
  padding: 10px 20px;
  border-top: 1px solid $divider-color;
}

th {
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  background: $base-background-color;
}


.incell-title {
  display: inline-block;
  &-prices {
    width: 60px;
    color: #999;
  }
  &-gateway {
    width: 200px;
    color: #999;
  }
}

.incell-textline {
  display: inline-block;
  &-name {
    display: inline-block;
    padding-bottom: 10px;
  }
  &-prices {
    width: 80px;
    text-align: right;
  }
  &-gateway {
    width: 200px;
    text-align: left;
  }
  &-meta {
    color: #999;
    white-space: nowrap;
  }
}


// --- Product container ---
.product-img-container {
  display: inline-block;
  position: relative;
  margin:0 5px 3px 0;
  img {
    border: 3px solid #333;
  }
  .color-size-purchase{
    position: absolute;
    bottom: 5px;
    right: 0;
    padding: 2px 6px;
    background:#333;

    font-size: 11px;
    color: #fff;
  }


  .description-purchase {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 6px;
    background:#333;

    font-size: 11px;
    color: #fff;
  }
  .description-purchase-note {
    display: none;
    position: absolute;
    top: 0;
    left: 34px;
    width: 200px;
    height: 100px;
    z-index: 1000;
    padding: 4px;
    background: #eee;
    color: #333;
    border: 2px solid #333;
  }

  &.product-status {
    &-8, &-9, &-10, &-11 {
      img{
        border-color: $api-status-1;
      }
      .color-size-purchase,
      .description-purchase {
        background: $api-status-1;
      }
      .description-purchase-note {
        border: 2px solid $api-status-1;
      }
    }
    &-19, &-20, &-21 {
      img{
        border-color: $api-status-2;
      }
      .color-size-purchase,
      .description-purchase {
        background: $api-status-2;
      }
      .description-purchase-note {
        border: 2px solid $api-status-2;
      }
    }
    &-22 {
      img{
        border-color: $api-status-3;
      }
      .color-size-purchase,
      .description-purchase {
        background: $api-status-3;
      }
      .description-purchase-note {
        border: 2px solid $api-status-3;
      }
    }
    &-100 {
      img{
        border-color: $api-status-4;
      }
      .color-size-purchase,
      .description-purchase {
        background: $api-status-4;
      }
      .description-purchase-note {
        border: 2px solid $api-status-4;
      }
    }
  }
}

.description-purchase:hover .description-purchase-note {
  display: block;
}

.light-grey {
  color: $color-light;
}
.main-navigation {
  &-list {
    margin: 0;
    padding: 0;
    background: #555;
    border-radius: $form-border-radius;
  }
  &-item {
    display: inline-block;
    list-style: none;
  }
  &-link {
    display: block;
    padding: 10px 15px;
    border-radius: $form-border-radius;

    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;

    &:hover {
      color: #fff;
      background: #000;
    }
  }
}